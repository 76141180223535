import { useEffect } from "react";
import "./style.scss";
import { DocumentEditorContainerComponent } from "@syncfusion/ej2-react-documenteditor";

const VersionListData = (p) => {
  let docRef: any = null;

  useEffect(() => {
    if (docRef) {
      const sfdtText = p?.list?.editor_data || "";
      docRef.documentEditor.open(sfdtText);
    }
  }, []);

  return (
    <>
      {p?.list?.editor_data ? (
        <DocumentEditorContainerComponent
          id="ej2-container-version-list"
          ref={(scope) => {
            docRef = scope;
          }}
          toolbarItems={[
            "New",
            "Open",
            "Separator",
            "Undo",
            "Redo",
            "Separator",
            "Find",
            "Comments",
            "FormFields",
          ]}
          serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
          enableToolbar={false}
          showPropertiesPane={false}
          restrictEditing={true}
        />
      ) : (
        <div style={{ display: "grid", textAlign: "center", justifyContent: "center" }}>
          NO DATA FOUND
        </div>
      )}
    </>
  );
};
export default VersionListData;
