import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { ToolTip } from 'components';
import { ConfirmationDialog } from 'customComponents';
import { showToast } from 'helpers';


const IFrameModal = ({ data = {}, state = false, confirmModalMsg = 'Are you sure you want to exit?', onClose = (v: boolean) => { }, }) => {

    // STATES
    const [confirmationDialogState, setConfirmationDialogState] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // FUNCTIONS
    const iframeDialogHandler = () => {
        setConfirmationDialogState(true);
    }
    const confirmationDialogHandler = (value: any) => {
        setConfirmationDialogState(false);
        return onClose(value);
    }
    const generateSharedUrl = () => {
        if (data?.result?.annotations?.length <= 0) {
            showToast("Please try again later", false);
            onClose(true);
        } else {
            if (Object.keys(data).length > 0) {
                return `https://doccompare.mikelegal.com/viewer?uuid=${data?.shared_url?.toString()?.split("/")?.reverse()?.[0]}&type=SHARED`
            } else {
                showToast("Please try again later", false);
                onClose(true);
            }
        }
    }

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setIsLoading(false)
        }, 8000);

        return () => {
            clearTimeout(timeOut);
        }
    }, [])

    return (
        <>
            <Dialog
                fullScreen
                open={state}
                onClose={iframeDialogHandler}
                className='!bg-[#F8F9FD]'
            >
                <div className="relative">
                    <div className='absolute w-full h-[14%] flex justify-center items-center bg-[#F8F9FD]'>
                        <div className='w-[97%] h-full flex justify-end items-center'>
                            <ToolTip arrow={true} title='Close' >
                                <IconButton onClick={iframeDialogHandler} className='!bg-[#E34234] !text-white'>
                                    <AiOutlineClose className='cursor-pointer font-bold' size={28} onClick={iframeDialogHandler} />
                                </IconButton>
                            </ToolTip>
                        </div>
                    </div>

                    {isLoading && <DialogContent className='!w-full !h-screen !bg-[#F8F9FD] !grid !place-content-center !place-items-center'>
                        <h1>Loading...</h1>
                    </DialogContent>}

                    <DialogContent className={`!m-0 !p-0 !bg-[#F8F9FD] !w-full !h-[95vh] ${isLoading ? "hidden" : "block"}`}>
                        <iframe src={generateSharedUrl()}
                            style={{ width: "100%", height: "95vh" }}></iframe>
                    </DialogContent>
                </div>
            </Dialog>
            <ConfirmationDialog
                state={confirmationDialogState}
                message={confirmModalMsg}
                onClose={confirmationDialogHandler}
            />
        </>
    )
}

export default IFrameModal;